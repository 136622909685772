
import './App.css';
import Navbar from './components/Navbar';
import Content from './components/Content';
// import homeImage from './maddison-mcmurrin-GDumtPpJsT4-unsplash.jpg';
import PaidLogo from './assets/Psychology-Today-Verified.webp'
import UCLA from './campus-logo.png'
import Footer from './components/Footer';

function App() {
  return (
    <div id="Home"className='bg-[#F1F1F1]'>
      <Navbar></Navbar> 
      <div className=' border-[#808080]'>
        <div className="bg-no-repeat bg-cover sm:bg-fixed bg-[url('./jeremy-thomas-FO7bKvgETgQ-unsplash.jpg')] ">
        <div className="min-h-[50vh] min-w-max flex items-center justify-center">
        <div className="text-[#114084] text-2xl md:text-5xl text-left rounded font-['PlayfairDisplay']">
          <div className='grid grid-rows-4 grid-flow-col '>
            <div className='row-span-1 text-center'>
            You Deserve Mental Wellness 
            </div>
          </div>
          {/* Barbara Salinger-Brown
          <br></br>
          Counseling and Coaching
          <br></br>
          Adolescents, Young Adults, Individuals & Family */}
          </div>
          </div>
        </div>
      {/* <img src={homeImage} alt="homeImage" /> */}
      </div>
      <div className="grid grid-cols-1 gap-4 bg-[#F1F1F1] min-h-fit text-[#114084] text-4xl font-['PlayfairDisplay'] text-center pb-5 pt-5">
        Bobbie Salinger-Brown
      <a className=" place-self-center" href='https://www.psychologytoday.com/us/therapists/barbara-salinger-brown-menlo-park-ca/994470'>
      <img src={PaidLogo} alt="PaidLogo" className='max-h-[75px]' />
      </a>
      </div>
      <div className='flex justify-center mb-[10px]'>
      <div id='About' className='border-t-2 border-[#808080] w-5/6'></div>
      </div>

      <br ></br>
      <Content />
      <br></br>
      <Footer />
      </div>
  );
}

export default App;
