

import React from "react";
import Modal from "react-modal";
import CustomModal from "./modal";
export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div>
    <nav className="sticky top-0 z-50 bg-[#114084] drop-shadow font-['PlayfairDisplay']">
      <button
        className="text-white  px-3 py-1 border border-transparent rounded  block md:hidden outline-none focus:outline-none h-[60px]"
        type="button"
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      <div
        className={"md:flex flex-col" + (navbarOpen ? "flex " : " hidden")}
        id="example-navbar-danger"
      >
        <ul
          className={
            navbarOpen
              ? ""
              : "flex justify-items-center items-center h-[60px] pl-4 pr-4"
          }
        >
          <li className="">
            <a
              className=" inline-block  hover:border-[#808080] text-[#F1F1F1] hover:text-[#1C1C1C] hover:bg-[#F1F1F1] py-2 px-4"
              href="#Home"
            >
              Home
            </a>
          </li>
          <li className="">
            {/* <button type="button"
            onClick={() => console.log("test")}
              className=" inline-block  hover:border-[#808080] text-[#F1F1F1] hover:text-[#1C1C1C] hover:bg-[#F1F1F1] py-2 px-4" onClick={toggleModal}
            >
              Contact
            </button> */}
            <button
        className="inline-block  hover:border-[#808080] text-[#F1F1F1] hover:text-[#1C1C1C] hover:bg-[#F1F1F1] py-2 px-4  ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Contact
      </button>
          </li>
          <li className="">
            <a
              className=" inline-block  hover:border-[#808080] text-[#F1F1F1] hover:text-[#1C1C1C] hover:bg-[#F1F1F1] py-2 px-4"
              href="#About"
            >
              About
            </a>
          </li>
          <li className="">
            <a
              className=" inline-block  hover:border-[#808080] text-[#F1F1F1] hover:text-[#1C1C1C] hover:bg-[#F1F1F1] py-2 px-4"
              href="#Philosophy"
            >
              Philosophy
            </a>
          </li>
          <li className="">
            <a
              className=" inline-block  hover:border-[#808080] text-[#F1F1F1] hover:text-[#1C1C1C] hover:bg-[#F1F1F1] py-2 px-4"
              href="#Training"
            >
              Training, Certifications and Experience
            </a>
          </li>
          <li className="">
            <a
              className=" inline-block  hover:border-[#808080] text-[#F1F1F1] hover:text-[#1C1C1C] hover:bg-[#F1F1F1] py-2 px-4"
              href="#Memberships"
            >
              Memberships
            </a>
          </li>
        </ul>
      </div>
    </nav>
    {showModal ? (
        <>
          <div
            className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                  Contact Information
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-8 text-lg leading-relaxed">
                  Bobbie Salinger-Brown:
                  </p>
                  <a className ="underline text-blue-600"href="tel:650-380-7032">Phone: (650) 380-7032 </a>
                    <br></br>
                    <br></br>
                   <a className ="underline text-blue-600" href = "mailto: bobbie.salinger@gmail.com">Email: bobbie.salinger@gmail.com</a>
                
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  {/* <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
   </div>
  );
}
