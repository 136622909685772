import bobbieSerial from "/Users/connorspackman/Documents/bobbie_website/client/src/assets/bobbie_serialKiller.jpeg"
import lavenderField from "/Users/connorspackman/Documents/bobbie_website/client/src/assets/pexels-david-bartus-1166209.jpg"
import flower from "/Users/connorspackman/Documents/bobbie_website/client/src/assets/saffu-A7RzCegedb4-unsplash.jpg"
import sand from "/Users/connorspackman/Documents/bobbie_website/client/src/assets/sand.jpg"
function Content() {
    return (
        <div  className="grid grid-cols-1 md:grid-cols-5  bg-[#F1F1F1] font-['PlayfairDisplay']">
            <div  className="rounded-lg  col-span-3 p-6">
                <h1 className="text-center text-[#3466AA] text-5xl">About Me</h1>
                <p className="text-[#3d3d3d] pt-8">Barbara (Bobbie) Salinger-Brown is a licensed psychotherapist with a private practice in Menlo Park, California. She has over 17 years of experience working with adults and teens who are seeking help for struggles with anxiety, depression, eating disorders, relief from trauma, and a poor self image. Bobbie also has a special interest and expertise in enhancing performance for athletes, coaches, and performing artists. She gives her clients tools to help increase their confidence while learning how to achieve peak performance. As a competitive swimmer for most of her life, she discovered a passion and a need for helping athletes develop a more balanced and healthy way of participating in their sport. Bobbie pursued her undergraduate education at UCLA. She subsequently completed her post graduate education at California State University Northridge within the Counseling Program, graduating with distinction.
                </p>
            </div>
            <div  className="col-span-2 bg-[#F1F1F1] ">
                <img className="mx-auto p-4" src={bobbieSerial}></img>
            </div >
            <div className="col-span-2 bg-[#F1F1F1] ">
                <img id = "Philosophy" className="mx-auto p-4" src={lavenderField}></img>
            </div>
            <div   className="rounded-lg col-span-3   p-6">
            <h1 className="text-center text-[#3466AA] text-5xl">Philosophy</h1>
            <p className="text-[#3d3d3d] pt-8">
            We all struggle from time to time and my hope is that you will reach out to someone to help you understand and learn how to cope more effectively. I can help you acquire practical skills and solutions to life's challenges. My approach is to provide a strong therapeutic, supportive and nurturing environment that encourages sharing and optimizes mental health and performance. As a trained unbiased professional I help most clients come away from our work together better equipped to overcome stressors from their personal, academic, and professional lives. I invite you to contact me to explore how you can live a more optimal life and improve your mental wellness
                </p>
            </div>
            <div id = "Training" className="col-span-3 bg-[#F1F1F1] p-6">
            <h1 className="text-center text-[#3466AA] text-5xl">Training, Certifications and Experience</h1>
            <p className="text-[#3d3d3d] pt-8">
            I have been trained in evidence based treatments such as, Cognitive Behavioral Therapy (CBT), Dialectical Behavioral Therapy (DBT), Eye Movement Desensitization and Reprocessing (EMDR), Mindfulness, and Performance and Sports Psychology.
I have completed internship training programs in Early Childhood at the Department of Psychiatry and Mental Health at Cedars-sinai Medical Center, The Boys and Girls Club of Venice, Pine Grove Behavioral Health Psychiatric Treatment Center, and The Community Counseling Resource Institute on the campus of California State University Northridge.
I am also affiliated with Immigrants Rising Mental Health and Wellness Program and the Frontline Workers Counseling Project.
                </p>
            </div>
            <div className="rounded-lg col-span-2 ">
            <img  className="mx-auto p-4" src={sand}></img>
            </div>
            <div className="col-span-2 bg-[#F1F1F1] ">
                <img id = "Memberships" className="mx-auto p-4" src={flower}></img>
            </div>
            <div  className="rounded-lg col-span-3   p-6">
            <h1 className="text-center text-[#3466AA] text-5xl">Memberships</h1>
            <ul role="list" className=" pt-8 text-[#3d3d3d] marker:text-[#3466AA]  list-disc pl-5 space-y-3">
            <li>Association for Applied Sport Psychology</li>
            <li>California Association of Marriage Family Therapists</li>
            <li>National Alliance on Mental Illness</li>
            <li>Phi Kappa Phi Honor Society</li>
                </ul>
            </div>
      </div>
    );
  }
  
  export default Content;